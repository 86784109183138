import React from "react";
import { useTranslation } from "react-i18next";
import { IImage } from "../Interface/IImage.interface";
import { map } from "lodash";
import Img from "../../UI/Img/Img.view";
import "./Style/Images.css";

export interface IImagesViewProps {
    imageMap: IImage[];
}

const ImagesView: React.FC<IImagesViewProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="images-view" id={t("hash_2")}>
            {map(props.imageMap, (image: IImage, index: number) => (
                <div className="image-frame" key={`${index}-image`}>
                    <div className="image-frame__type-name">{image.typeName}</div>
                    <Img src={image.src} className="image-frame__image" alt={image.typeName} />
                </div>
            ))}
        </div>
    );
};

export default ImagesView;
