import React from "react";

export interface IImgProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

const Img: React.FC<IImgProps> = ({ alt, ...props }) => {
    return (
        <img
            {...props}
            alt={alt}
            onContextMenu={(e) => {
                e.preventDefault();
                return false;
            }}
            onContextMenuCapture={(e) => {
                e.preventDefault();
                return false;
            }}
            onDragStart={(e) => {
                e.preventDefault();
                return false;
            }}
            onDrag={(e) => {
                e.preventDefault();
                return false;
            }}
            draggable={false}
        />
    );
};

export default Img;
