import React from "react";
import { IShowcase } from "../Interface/IShowcase.interface";
import { ShowcaseList } from "./ShowcaseList.view";
import { useTranslation } from "react-i18next";
import "./Style/Showcase.css";

export interface IShowcaseViewProps {
    personalShowcases: IShowcase[];
    groupShowcases: IShowcase[];
}

const ShowcaseView: React.FC<IShowcaseViewProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="showcase" id={t("hash_3")}>
            <h2 className="showcase__title">{t("former_exhibitions")}</h2>
            <ShowcaseList subTitle={t("personal_showcase")} showcases={props.personalShowcases} />
            <ShowcaseList subTitle={t("group_showcase")} showcases={props.groupShowcases} />
        </div>
    );
};

export default ShowcaseView;
