import React from "react";
import { useTranslation } from "react-i18next";
import ImagesController from "../../Images/Controller/Images.controller";
import Availability from "../../Availability/View/Availability.view";
import Showcase from "../../Showcase/Controller/Showcase.controller";
import Profile from "../../../Assets/profile.jpg";
import Img from "../../UI/Img/Img.view";
import "./Style/MainPage.css";

export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="main-page" id="gyarfasi-miklos">
            <h1 className="main-title">{t("name")}</h1>
            <div className="main-page__block">
                <div className="main-page__block--image-container">
                    <Img src={Profile} alt="Gyárfási Miklós" className="img" />
                </div>
                <div className="main-page__block--text-container">
                    <p className="text-intro">{t("intro_1")}</p>
                    <p className="text-intro">{t("intro_2")}</p>
                </div>
            </div>
            <h2 className="text-title" id={t("hash_1")}>
                {t("my_history")}
            </h2>
            <p className="text-common">{t("my_history_1")}</p>
            <p className="text-common">{t("my_history_2")}</p>
            <p className="text-common">{t("my_history_3")}</p>
            <p className="text-cite">"{t("cite")}"</p>

            <ImagesController />
            <div className="main-page__bottom">
                <Showcase />
                <div className="main-page__technical-details">
                    <h2 className="title">{t("technical_details_title")}</h2>
                    <p className="text-common">{t("technical_details_1")}</p>
                    <p className="text-common">{t("technical_details_2")}</p>
                    <p className="text-common">{t("technical_details_3")}</p>
                    <p className="text-common">{t("technical_details_4")}</p>
                </div>
            </div>
            <Availability />
        </div>
    );
};

export default MainPageView;
