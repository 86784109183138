import React, { useState } from "react";
import { defaultLanguage } from "../../../index";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { ILang } from "../Interfaces/ILang.interface";
import Select from "react-select";
import "./Style/Navbar.css";

export interface INavbarProps {}

const Navbar: React.FC<INavbarProps> = (props) => {
    const languages: ILang[] = [
        { value: "hu", label: "Magyar" },
        { value: "en", label: "English" }
    ];

    const init = (): ILang => {
        const option: ILang | undefined = languages.find(
            (lang: ILang) => lang.value === defaultLanguage
        );
        return option || languages[0];
    };
    const [isOpened, setisOpened] = useState<boolean>(false);
    const [selectedLang, setSelectedLang] = useState<ILang>(init());
    const { t, i18n } = useTranslation();

    const handleChange = (newLang: ILang): void => {
        i18n.changeLanguage(newLang.value, () => {
            setSelectedLang(newLang);
            localStorage.setItem("lang", newLang.value);
        });
    };

    let classes: string[] = ["layout"];
    if (isOpened) classes.push("layout-opened");

    return (
        <div className={classes.join(" ")}>
            <div className="threads" onClick={() => setisOpened(!isOpened)}>
                <div className="thread"></div>
                <div className="thread"></div>
                <div className="thread"></div>
            </div>
            <div className="layout__sidemenu">
                <HashLink smooth onClick={() => setisOpened(false)} to={`#${t("hash_1")}`}>
                    <div className="nav-item">{t("nav_history")}</div>
                </HashLink>
                <HashLink smooth onClick={() => setisOpened(false)} to={`#${t("hash_2")}`}>
                    <div className="nav-item">{t("nav_images")}</div>
                </HashLink>
                <HashLink smooth onClick={() => setisOpened(false)} to={`#${t("hash_3")}`}>
                    <div className="nav-item">{t("nav_showcase")}</div>
                </HashLink>
                <HashLink smooth onClick={() => setisOpened(false)} to={`#${t("hash_4")}`}>
                    <div className="nav-item">{t("nav_ava")}</div>
                </HashLink>

                <Select
                    className="select-input"
                    classNamePrefix={"select-input"}
                    value={selectedLang}
                    options={languages}
                    onChange={(value) => handleChange(value as ILang)}
                />
            </div>
            {isOpened && <div className="layout__overlay" onClick={() => setisOpened(false)}></div>}
            <div className="layout__container" id="layout-container">
                {props.children}
            </div>
        </div>
    );
};

export default Navbar;
