import React from "react";
import { IImage } from "../Interface/IImage.interface";
import ImagesView from "../View/Images.view";
import Obsession from "../../../Assets/Obsession.jpeg";
import Identity from "../../../Assets/Identity.jpeg";
import Outsider from "../../../Assets/Outsider.jpeg";
import Genesis from "../../../Assets/Genesis.jpeg";
import Watcher from "../../../Assets/Watcher.jpeg";
import Trance from "../../../Assets/Trance.jpeg";
import Reborn from "../../../Assets/Reborn.jpeg";
import Mirage from "../../../Assets/Mirage.jpeg";
import Change from "../../../Assets/Change.jpeg";
import Erase from "../../../Assets/Erase.jpeg";
import Infra from "../../../Assets/Infrared.jpeg";
import Bound from "../../../Assets/Bound.jpeg";
import Alone from "../../../Assets/Alone.jpeg";
import Away from "../../../Assets/Away.jpeg";
import Omen from "../../../Assets/Omen.jpeg";
import Ego from "../../../Assets/EGO.jpeg";

export interface IImagesControllerProps {}

const ImagesController: React.FC<IImagesControllerProps> = (props) => {
    const imageMap: IImage[] = [
        {
            typeName: "infrared",
            src: Infra
        },
        {
            typeName: "change",
            src: Change
        },
        {
            typeName: "bound",
            src: Bound
        },
        {
            typeName: "mirage",
            src: Mirage
        },
        {
            typeName: "alone",
            src: Alone
        },
        {
            typeName: "reborn",
            src: Reborn
        },
        {
            typeName: "ego",
            src: Ego
        },
        {
            typeName: "erase",
            src: Erase
        },
        {
            typeName: "trance",
            src: Trance
        },
        {
            typeName: "outsider",
            src: Outsider
        },
        {
            typeName: "omen",
            src: Omen
        },
        {
            typeName: "genesis",
            src: Genesis
        },
        {
            typeName: "obsession",
            src: Obsession
        },
        {
            typeName: "away",
            src: Away
        },
        {
            typeName: "identity",
            src: Identity
        },
        {
            typeName: "watcher",
            src: Watcher
        }
    ];
    return <ImagesView imageMap={imageMap} />;
};

export default ImagesController;
