import React from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import E404PageNotFound from "../WarnPages/View/E404";
import MainPageView from "../MainPage/View/MainPage.view";
import Navbar from "../Navbar/View/Navbar.view";

export interface IRouterProps extends RouteComponentProps {}

const Router: React.FC<IRouterProps> = (props) => {
    return (
        <Navbar>
            <Switch>
                <Route path={"/"} exact component={MainPageView} />
                <Route path={"*"} exact component={E404PageNotFound} />
            </Switch>
        </Navbar>
    );
};

export default withRouter(Router);
