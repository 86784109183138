import React from "react";
import "./Button.css";

export interface IButtonProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    hasContainer?: boolean;
    align?: "left" | "right";
    textButton?: boolean;
    linkButton?: boolean;
    fullWidth?: boolean;
    className?: string;
    disabled?: boolean;
    altColor?: boolean;
    isWide?: boolean;
    type?: "reset" | "submit" | "button";
}
/**
 * ## Button props
 * - **onClick?: () => any;** - The click event.
 * - **hasContainer?: boolean;** - Puts the button into a container div which has a width of 100%.
 * - **align?: "left"| "right";** - If hasContainer was set to true, you can set its alignment. Defaults to center.
 * - **textButton?: boolean;** -  Sets if the button should look like a text button.
 * - **linkButton?: boolean;** -  Sets if the button should be a link button. Just add a history push event to the onClick for redirection.
 * - **fullWidth?: boolean;** -  Sets if the button should take on 100% of the width.
 * - **className?: string;** -  You can add your own className to the button. (This doesn't affect the container.)
 * - **disabled?: boolean;** -  Controls whether the button is disabled or not.
 * - **altColor?: boolean;** - Turns the button into the alternative coloured version.
 * - **isWide?: boolean;** - Increases the left and right padding for the button.
 *
 * ## Template .less
 * Template less file to define the colors and possibly override the default styling.
 * Note that neither of the classes have their colour set so you won't have to use
 * !important for those. If you wish to override the default stylings, like border-radius and paddings,
 * you may have to use !important depending on the import order.
 * In that case, you may want to create a wrapper component for this button and make sure that
 * your own css file is imported AFTER the package Button.
 * ```less
 *	.xa-button {
 *		&__wide-button {
 *			background-color: #ccc;
 *			color: #333;
 *			&:hover { background-color: #aaa; }
 *		}
 *		&__alt-color {
 *			background-color: #ccc;
 *			color: #333;
 *			&:hover { background-color: #aaa; }
 *		}
 *		&__text-type {
 *			//bg is transparent by default
 *			color: #333;
 *			&:hover { color: #aaa; }
 *		}
 *		&__link-button {
 *			//bg is transparent by default
 *			color: #333;
 *			&:hover { background-color: #aaa; }
 *		}
 *		&__full-width {
 *			background-color: #ccc;
 *			color: #333;
 *			&:hover { background-color: #aaa; }
 *		}
 *	}
 *	.xa-button:disabled {
 *		color: #ccc;
 *	}
 * ```
 */
export const Button: React.FC<IButtonProps> = (props): React.ReactElement => {
    const getButton = (): React.ReactElement => {
        let classString: string = `xa-button`;
        if (props.textButton) classString += ` xa-button__text-type`;
        if (props.linkButton) classString += ` xa-button__link-button`;
        if (props.fullWidth) classString += ` xa-button__full-width`;
        if (props.altColor) classString += ` xa-button__alt-color`;
        if (props.isWide) classString += ` xa-button__wide-button`;
        if (props.className) classString += ` ${props.className}`;

        return (
            <button
                onClick={props.onClick}
                disabled={props.disabled}
                className={classString}
                type={props.type}
            >
                {props.children}
            </button>
        );
    };

    if (props.hasContainer) {
        let containerClasses: string[] = ["button-wrapper"];
        if (props.align) containerClasses.push(`button-wrapper--${props.align}`);

        return <div className={containerClasses.join(" ")}>{getButton()}</div>;
    }

    return <React.Fragment>{getButton()}</React.Fragment>;
};
