import React from "react";
import { useTranslation } from "react-i18next";
import "./Style/Availability.css";

export interface IAvailabilityProps {}

const Availability: React.FC<IAvailabilityProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="availability" id={`${t("hash_4")}`}>
            <h2 className="availability__title">{t("availability")}</h2>
            <div className="availability__data">
                <div className="availability__data--row">
                    <div className="availability__data--row--icon-container">
                        <span className="flaticon-location-pin ava-icon"></span>
                    </div>
                    <a
                        target={"_blank"}
                        rel="noreferrer noopener"
                        href={`https://www.google.com/maps/place/Budapest/@47.4808722,18.8501225,10z/data=!3m1!4b1!4m5!3m4!1s0x4741c334d1d4cfc9:0x400c4290c1e1160!8m2!3d47.497912!4d19.040235`}
                        className="availability__data--row--text link"
                    >
                        Budapest
                    </a>
                </div>
                <div className="availability__data--row">
                    <div className="availability__data--row--icon-container">
                        <span className="flaticon-phone-call ava-icon"></span>
                    </div>
                    <a
                        target={"_blank"}
                        rel="noreferrer noopener"
                        href={`tel:+36703252077`}
                        className="availability__data--row--text link"
                    >
                        +36 70 325 20 77
                    </a>
                </div>
                <div className="availability__data--row">
                    <div className="availability__data--row--icon-container">
                        <span className="flaticon-email ava-icon"></span>
                    </div>
                    <a
                        target={"_blank"}
                        rel="noreferrer noopener"
                        href={"mailto:gyarfas.miklos@gmail.com"}
                        className="availability__data--row--text link"
                    >
                        gyarfas.miklos@gmail.com
                    </a>
                </div>
                <div className="availability__data--row">
                    <div className="availability__data--row--icon-container">
                        <span className="flaticon-facebook ava-icon"></span>
                    </div>
                    <a
                        target={"_blank"}
                        rel="noreferrer noopener"
                        href={`https://facebook.com/gyarfasmiklosart/`}
                        className="availability__data--row--text link"
                    >
                        https://facebook.com/gyarfasmiklosart/
                    </a>
                </div>
                <div className="availability__data--row">
                    <div className="availability__data--row--icon-container">
                        <span className="flaticon-instagram ava-icon"></span>
                    </div>
                    <a
                        target={"_blank"}
                        rel="noreferrer noopener"
                        href={`https://instagram.com/gyarfasmiklos/`}
                        className="availability__data--row--text link"
                    >
                        https://instagram.com/gyarfasmiklos/
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Availability;
