import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { History } from "history";
import { Button } from "../../UI/Button/Button.view";
import "./Style/WarnPages.css";

export interface IE404PageNotFoundProps { }

const E404PageNotFound: React.FC<IE404PageNotFoundProps> = (props): React.ReactElement => {
    const history = useHistory() as History;
    const { t } = useTranslation();

    return (
        <div className="warn-page-wrapper">
            <div className="warn-page e404">
                <h2 className="warn-page__title">
                    <span>404</span> <br /> {t("page_not_found_title")}
                </h2>
                <div className="warn-page__container">
                    <p className="warn-page__container--text">
                        {t("page_not_found_text1")} <span>"{history.location.pathname}"</span>{" "}
                        {t("page_not_found_text2")} {t("page_not_found_text3")}
                    </p>
                    <div className="warn-page__container--buttons">
                        <Button onClick={() => history.goBack()}>{t("go_back")}</Button>
                        <Button onClick={() => history.push("/")}>{t("to_main_page")}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default E404PageNotFound;
