import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import * as ServiceWorker from "./serviceWorker";
import Router from "./Components/Router/Router.view";
import i18n from "i18next";
import HU from "./Lang/HU.json";
import EN from "./Lang/EN.json";
import "./Assets/font/flaticon.css";
import "./Css/SharedGlobal.css";
import "./Css/Fonts.css";

export let defaultLanguage = localStorage.getItem("lang") || "hu";

i18n.use(initReactI18next).init({
    resources: {
        hu: {
            translation: HU
        },
        en: {
            translation: EN
        }
    },
    lng: defaultLanguage,
    fallbackLng: "hu",
    interpolation: {
        escapeValue: true
    }
});

const AppRoot = (
    <BrowserRouter>
        <Router />
    </BrowserRouter>
);

ReactDOM.render(AppRoot, document.getElementById("root"));
ServiceWorker.unregister();
