import React from "react";
import ShowcaseView from "../View/Showcase.view";
import { IShowcase } from "../Interface/IShowcase.interface";

export interface IShowcaseProps {}

const Showcase: React.FC<IShowcaseProps> = (props) => {
    const personalShowcases: IShowcase[] = [
        {
            date: "2017",
            show: "A38 Hajó",
            place: "Budapest"
        },
        {
            date: "2017",
            show: "Pótkulcs",
            place: "Budapest"
        },
        {
            date: "2017",
            show: "Ágens Galéria",
            place: "Budapest"
        },
        {
            date: "2017",
            show: "27. Művészetek Völgye",
            place: "Kapolcs"
        },
        {
            date: "2017",
            show: "Corner Ékszerház és Galéria",
            place: "Budapest"
        },
        {
            date: "2017",
            show: "Nagykátai Városi Könyvtár",
            place: "Nagykáta"
        },
        {
            date: "2017",
            show: "Hadik Kávéház",
            place: "Budapest"
        },
        // {
        //     date: "2017",
        //     show: "Moha Café",
        //     place: "Budapest"
        // },
        // {
        //     date: "2018",
        //     show: "Görbe Bögre Kávézó",
        //     place: "Budapest"
        // },
        {
            date: "2018",
            show: "28. Művészetek Völgye",
            place: "Kapolcs"
        },
        {
            date: "2019",
            show: "Montázs Art Café",
            place: "Budapest"
        },
        {
            date: "2019",
            show: "Titok Galéria",
            place: "Budapest"
        },

        {
            date: "2020",
            show: "ROOMS - Art & Me Galéria",
            place: "Budapest"
        },
        {
            date: "2020",
            show: "Babicska",
            place: "Budapest"
        },
        {
            date: "2023",
            show: "Nagykörű Galéria",
            place: "Nagykörű"
        }
    ];

    const groupShowcases: IShowcase[] = [
        {
            date: "2019",
            show: "Budapesti Művelődés Központ",
            place: "Budapest"
        },
        {
            date: "2020",
            show: "SOON Art Studio",
            place: "Bécs"
        },
        {
            date: "2020",
            show: "Szimpla Kert - Vad Art",
            place: "Budapest"
        },
        {
            date: "2022",
            show: "Bakelit Multi Art Center - The Talent",
            place: "Budapest"
        },
        {
            date: "2023",
            show: "Gödör Klub - Vad Art",
            place: "Budapest"
        }
    ];
    return <ShowcaseView personalShowcases={personalShowcases} groupShowcases={groupShowcases} />;
};

export default Showcase;
