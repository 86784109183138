import React from "react";
import { FC } from "react";
import { map } from "lodash";
import { IShowcase } from "../Interface/IShowcase.interface";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

interface IShowcaseListProps {
    subTitle: string;
    showcases: IShowcase[];
}

export const ShowcaseList: FC<IShowcaseListProps> = (props) => {
    const { t } = useTranslation();
    let delay = 0;
    let showcaseLength = props.showcases.length - 1;

    return (
        <div className={"showcase__list"}>
            <div className="showcase__list--sub-title">{props.subTitle}</div>
            <ScrollAnimation
                scrollableParentSelector={"#layout-container"}
                animateIn="animate-in-showcase"
                initiallyVisible
                delay={100}
            >
                {map(props.showcases, (show: IShowcase, index: number) => {
                    delay += 0.2;
                    return (
                        <div className="showcase__text-container" key={`${index}-showcase`}>
                            <div className="showcase__text-container--dot">
                                <div className="circle"></div>
                            </div>
                            {index < showcaseLength && (
                                <div className="line" style={{ animationDelay: `${delay}s` }} />
                            )}
                            <div className="showcase__text-container--date">{show.date}</div>
                            <div className="showcase__text-container--place">
                                {show.show} <span className="city"> | {t(show.place)}</span>
                            </div>
                        </div>
                    );
                })}
            </ScrollAnimation>
        </div>
    );
};
